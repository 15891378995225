.MemberPageImageContainer {
    min-height: 600px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    margin-left: 25%;
}
.MemberPage {
    display: flex;
}

.MemberImageSection {
    width: 30%;
}

.MemberInfoContainer {
    width: 40%;
    margin: 5% 0 0 15%;
}

.MemberInfoSection {
    height: auto;
    display: block;
    max-width: 1140px;
    background-color: #f7f7f7;
    flex-direction: column;
    padding: 10px;
    padding-right: 50px;
}
.MemberInfoSection > div:where(:last-child) {
    border: none;
}

@media (max-width: 1000px) {
    .MemberPage {
        flex-direction: column;
    }
    .MemberImageSection {
        width: 100%;
    }
    .MemberPageImageContainer {
        margin-left: 0;
    }
    .MemberInfoContainer {
        width: 100%;
        margin: 0;
    }
    .MemberInfoSection {
        padding: 0;
    }
}