.DropdownContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    top: 40px;
    left: -18px;
    z-index: 1;
    border-radius: 5px;
}

.SubDropdownContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    z-index: 1;
    border-radius: 5px;
    overflow: hidden;
}

.DropdownContainer > div:not(:last-child) {
    border-bottom: 1px solid #efeff0;
}

.DropdownItem {
    color: rgba(22, 22, 23, .8);
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    height: 40px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.DropdownItem:hover {
    background-color: #efeff0;
}

.DropdownLabel {
    width: 75%;
}
