.EventPriceContainer {
    margin: 0 20% 0 15%;
    color: #1a325e;
}


table{
    width: 100%;
    text-align: center;
    border-spacing: 2px;
    border-collapse: separate;
}

tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}

table tr th {
    color: white;
    background-color: var(--main-redness);
    padding: 15px 10px;
    font-size: 16px;
    font-weight: normal;
}

table tr td {
    padding: 15px 15px;
    height: 25px;
    border-bottom-style: solid;
    border-bottom-width: 0px;
}

table tr:nth-child(even) {
    color: #949494;
    background-color: #f3f3f3;
}

table tr:nth-child(odd) {
    color: #949494;
    background-color: #f9f9f9;
}

.QuantityContainer {
    display: flex;
    justify-content: center;
}

.QuantityContainer > div {
    margin: 0 10px;
    padding: 0 10px;
    display: flex;
}

.minusButton {
    cursor: pointer;
    border: none;
    background-color: var(--main-redness);
    color: white;
    width: 15px;
    height: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
}

.plusButton {
    cursor: pointer;
    border: none;
    background-color: var(--main-redness);
    color: white;
    width: 15px;
    height: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
}

.EventMemberCodeInput {
    display: flex;
}

.EventMemberCodeInput > div {
    margin: 5px 0;
    height: 30px;
}

.EventMemberCodeInput > div:first-child {
    width: 100%;
}

.EventMemberCodeInput > div:not(:first-child) {
    margin-left: 5px;
}

.CheckMark {
    height: 30px;
    width: 30px;
}

@media (max-width: 1000px) {
    .EventPriceContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10% 0 5% 0;
    }
}