.CalendarContainer {
    max-width: 80%;
    min-width: 100%;
}

.DatePickerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}


.DatePickerContainer > div:not(:last-child) {
    margin-right: 10px;
}