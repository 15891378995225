.FooterContainer {
    display: flex;
    flex-direction: row;
    background-color: #f7f6f6;
    margin-top: 50px;
    padding: 40px 0;
}

.FooterInfoContainer {
    display: flex;
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: space-around;
    align-items: center;
    height: auto;
    padding: 20px;
    border-left: 1px solid var(--main-redness);
    color: var(--main-redness);
}

.FooterLogoImage {
    height: 65px;
    width: 300px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.FooterHyperlinksContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.FooterHyperlinksContainer > div:not(:last-child) {
    margin-right: 10px;
}

@media (max-width: 1000px) {
    .FooterContainer {
        flex-direction: column;
    }
    .FooterInfoContainer {
        border-left: none;
        width: 100%;
    }
}