.PaymentDetailsContainer {
    width: 40%;
    padding: 0 5%;
    /* background-color: #ececef; */
    /* background: #7e1d20; */
    border-radius: 25px;
    background: rgb(97,1,1);
    background: linear-gradient(87deg, rgba(97,1,1,1) 0%, rgba(83,10,10,1) 52%, rgba(121,4,4,1) 85%);
    font-size: 16px;
    max-height: 600px;
}

.EventInfo {
    height: fit-content;
    margin: 5% 0;
}

.EventInfo > div {
    color: white;
    margin: 5px 0;
}

.OrderSummary {
    background-color: white;
    color: #999999;
    border: 1px solid #e2e4e5;
    border-radius: 16px;
    margin: 25px 0;
}

.OrderTotal {
    background-color: white;
    border-radius: 16px;
    height: 60px;
    margin-bottom: 40px;
}

.priceByLine {
    color: black;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin: 0px 25px 0 25px;
}

hr {
    margin: 0 25px;
}

@media (max-width: 1000px) {
    .PaymentDetailsContainer {
        width: 90%;
    }
}