.ButtonContainer {
    cursor: pointer;
    border: none;
    background-color: var(--main-redness);
    color: white;
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 10px 10px;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0;
}