.RegistrationContentContainer{
    display: flex;
}

.RegistrationInfoContainer {
    width: 100%;
    margin-left: 5%;
}

.RegistrationFormContainer {
    display: flex;
    flex-wrap: wrap;
    padding-left: 10%;
    margin: 5% 10%;
    width: 60%;
}
  
.RegistrationFormContainer > div {
    flex: 48% 1;
    height: auto;
    margin-right: 10px;
}

.RegistrationFormCreditCard {
    margin: 5% 25%;
    width: 50%;
}

.RegistrationButtonContainer {
    display: flex;
    justify-content: space-evenly;
    border: 0;
}

.FormButton {
    cursor: pointer;
    border: none;
    background-color: var(--main-redness);
    color: white;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    padding: 10px 10px;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0;
}

h1, p{
    color: #1a325e;
}

.RadioColor {
    color: #1a325e;
}

@media (max-width: 1000px) {
    .RegistrationContentContainer {
        flex-direction: column;
        align-items: center;
    }
    .RegistrationInfoContainer {
        margin-left: 0;
    }
}