.NewEventPopupContainer {
    position: fixed;
    height: 100%;
    background: rgba(0,0,0,0.5);
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.NewEventPopupLabel {
    display: flex;
    color: rgba(22, 22, 23, .8);
    font-size: 14px;
    white-space: nowrap;
    width: 100px;
    text-align: left;
}

.NewEventContainer {
    background: white;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 0px, rgb(0 0 0 / 10%) 0px 0px 1px 0px;
    border-radius: 4px;
    width: 50%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 50px 25px;
}

.NewEventContainer > div:not(:last-child) {
    margin-bottom: 10px;
}

.NewEventPopupInput {
    border-radius: 4px;
    border: 1px solid rgba(22, 22, 23, .2);
    width: 100%;
    margin-top: 10px;
}

.NewEventPopupInputContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.NewEventPopupFooter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.asterisk {
    color: red;
    margin-left: 5px;
}

.InputContainer {
}