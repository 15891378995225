.NewsThumbnailImageContainer {
    height: 70px;
    width: 100px;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.NewsThumbnailContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    cursor: pointer;
    width: fit-content;
    align-items: center;
    justify-content: center;
}

.NewsThumnailContentContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.NewsThumbnailInformationSection {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.NewsThumnailContentUpperSection {
    display: flex;
    flex-direction: column;
    height: 70px;
    justify-content: space-between;
    padding-left: 8px;
}

.NewsThumnailContentUpperSection:hover {
    background-color: #f2f2f2;
}

.NewsThumbnailDate {
    width: fit-content;
    text-align: initial;
    font-size: 10px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #a3a3a3;
}

.NewsThumbnailName {
    text-align: initial;
    width: 200px;
    font-size: 10px;
    overflow: hidden;
    color: #2578b3;
}

.NewsThumbnailTag {
    width: fit-content;
    min-width: 50px;
    height: 15px;
    padding: 5px;
    color: #6b6b6b;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background-color: #f7f7f7;
    font-size: 10px;
    border: 1px solid #dbdbdb;
    margin-bottom: 5px;
}

.NewsThumbnailTagContainer {
    max-width: 200px;
    flex-direction: row;
    display: flex;
    overflow: hidden;
    flex-flow: row wrap;
}

.NewsThumbnailTagContainer > div:not(:last-child) {
    margin-right: 5px;
}

@media (max-width: 1000px) {
    .NewsThumbnailContainer {
        flex-direction: column;
        margin-bottom: 0;
        margin-right: 10px;
    }
    .NewsThumbnailImageContainer {
        height: 200px;
        width: 100%;
    }
    .NewsThumnailContentContainer {
        height: auto;
    }
}