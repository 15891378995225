.EventPageImageContainer {
    min-height: 600px;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
}

.EventPageImageOverlayContainer {
    height: 300px;
    width: 600px;
    overflow: hidden;
}
.EventImageSection {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
}

.EventDetailsSection {
    width: fit-content;
}

.EventPage {
    display: flex;
    flex-direction: column;    
    text-align: left;
}

.EventPageInfoSection {
    display: block;
    max-width: 1140px;
    background-color: #f7f7f7;
    flex-direction: column;
    padding: 10px;
}

.EventPageInfoSection > div:not(:last-child) {
    margin-bottom: 15px;
}
.EventPageInfoSection > div:where(:last-child) {
    border: none;
}

.EventPageSpeakersSection {
    display: flex;
    flex-direction: column;
    border: 1px solid;
    width: 500px;
}

.EventPageInfoContainer {
    display: flex;
    border-bottom: 1px solid rgb(223,223,223);
}

.EventPageInfoSubTitle {
    font-weight: bold;
    color: #1a325e;
    width: 50%;
    height: 55px;
    text-transform: uppercase;
}

.EventPageInfoContent {
    font-family: "Montserrat";
    font-size: 18px;
    width: 80%;
    text-align: justify;
}

.EventRegisterButton {
    cursor: pointer;
    border: none;
    background-color: var(--main-redness);
    color: white;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 10px 10px;
    font-size: 16px;
    font-weight: bold;
    margin: 20px 0;
}

.EventPageInfoWrapper{
    margin: 0 auto;
    max-width: 850px;
    width: 50%;
}

.PageDetailsTitle{
    background-color:var(--main-redness);
    font-size: 26px;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    padding:20px 0 20px 2%;
    margin-bottom: 2px;
}

h1, h2, h3, h4, h5, h6 {
    line-height: 1.3;
    margin-bottom: 18px;
    font-weight: 400;
}

@media (max-width: 1000px) {
    .EventPageImageContainer {
        min-height: 240px;
    }
    .EventPageInfoWrapper {
        width: auto;
    }
    .EventPageInfoContent {
        width: auto;
    }
}