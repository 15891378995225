.EventThumbnailsContainer {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: space-between;
    padding-bottom: 5px;
}

.EventsSectionContainer {
    display: flex;
    flex-direction: column;
}

.EventThumbnailContainer {
    width: 500px;
    margin: 50px 10px 0 0;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 0px, rgb(0 0 0 / 10%) 0px 0px 1px 0px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    justify-content: space-between;
    padding-bottom: 10px;
    height: fit-content;
}

.EventImageContainer {
    height: 200px;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.EventName {
    font-size: 13pt;
    color: #000;
    line-height: 18pt;
    font-weight: 600;
    height: 50px;
    justify-content: center;
    display: flex;
    overflow: hidden;
    align-items: center;
    text-overflow: ellipsis;
    width: 100%;
}

.EventName > * {
    vertical-align: middle;
}

.EventDetails {
    padding: 5px 10px 10px 10px;
    font-size: 11pt;
    line-height: 12pt;
    font-weight: 500;
    max-height: 100px;
    text-align: left;
    overflow: hidden;
}

.EventRegisterContainer {
    justify-content: space-between;
    display: flex;
    padding-right: 10px;
    padding-left: 10px;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
}

.EventDetailButton {
    color: #fff;
    background-color: #79afd3;
    font-size: 14px;
    line-height: 10px;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.EventCalendarContainer {
    display: flex;
    justify-content: center;
    margin: 50px;
}

.EventLocationContainer {
    display: flex;
    align-items: center;
}

.EventLocationSymbol {
    height: 20px;
}

.EventLocationLabel {
    font-size: 14px;
    margin-left: 4px;
}

.EventDate {
    font-size: 14px;
}

.EventDetailsLink {
    text-decoration: unset;
    color: rgba(255, 255, 255, .8);
}

.EventThumbnailSpacer {
    max-width: 20px;
    width: 100%;
}

@media (max-width: 1000px) {
    .EventThumbnailsContainer {
        flex-direction: column;
        align-items: center;
    }

    .EventThumbnailContainer {
        max-width: 95%;
        margin: 10px 0 0 0;    }
}