.LangaugeSelectorContainer {
    display: flex;
}

.LangaugeSelectorTab {
    float: left;
    height: 20px;
    margin: 0 8px -1px 0;
    border: 1px solid #DAE0E7;
    background: #F9F9F9;
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    width: 50px;
    cursor: pointer;
    text-transform: capitalize;
}

.LangaugeSelectorTabSelected {
    border-bottom: none;
    background: white;
    z-index: 1;
}