.month_default_event {
    overflow: hidden;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.month_default_event_inner {
    background: white;
    border-color: #777777;
    color: black;
    text-overflow: ellipsis;
    padding-left: 35px;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
}

.month_default_event_bar {
    left: 0px;
    width: 30px;

}
.month_default_event_bar_inner {
    background: green;
    border-radius: 5px;
    width: 3px;
}