.BecomeAMemberPageContainer {

}

.SubscriptionsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.MemberSubscriptionContainer {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    width: 100%;
}

.SubscriptionDescription {
    font-size: 15px;
}

.SubscriptionTitle {
    font-size: 20px;
}

.SubscriptionButton {
    color: #79afd3;
    border: 1px solid #79afd3;
    font-size: 14px;
    line-height: 10px;
    width: 200px;
    height: 35px;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px 20px 20px;
}

.SubscriptionBulletDot {
    width: 5px;
    height: 5px;
    border-radius: 50px;
    border: 1px solid #8a8a8a;
    margin-top: 6px;
    margin-right: 3px;
}

.SubscriptionBulletPoint {
    display: flex;
    white-space: nowrap;
    margin-bottom: 10px;
}

.SubscriptionBulletPointText {
    overflow: hidden;
    white-space: break-spaces;
    font-size: 11px;
    color: #8a8a8a;
    text-align: initial;
}

.SubcriptionHeaderContainer {
    display: flex;
    justify-content: space-between;
    margin: 20px;
}

.BecomeAMemberInformationContainer {
    border: 1px solid #8b8b8b;
    margin: 20px 0;
    font-size: 11px;
    border-radius: 2px;
    line-height: 5;
    text-align: initial;
    padding-left: 1%;
}