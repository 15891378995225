.CharterColumnContainer > .CharterColumn:not(:last-child) {
    margin-right: 5%;
}


.CharterPageSubtitle {
    font-weight: bold;
    text-indent: 0;
    font-size: large;
}

.CharterColumn {
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: left;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.CharterColumnContainer {
    display: flex;
    flex-direction: row;
}

.CharterButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.CharterButtonsContainer > div:first-child {
    margin-right: 5px;
}