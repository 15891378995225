.PopupEditorContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.PopupEditorTitleContainer {
    margin-bottom: 10px;
}

.ql-container {
    width: 500px;
}

.quill {
    width: 450px;
    overflow: overlay;
    max-height: 700px;
    min-height: 300px;
}