.EventPageBannerContainer{
    text-align: center;
    background: rgb(54,34,88);
    background: linear-gradient(281deg, rgba(54,34,88,1) 3%, var(--main-redness) 68%, rgba(18,44,97,1) 100%);
    margin-left: -20%;
    margin-right: -20%;
    margin-bottom: 3%;
    padding: 0 20%;
}

.EventPageBanner {
    justify-content: center;
}

.EventPageBannerTitle {
    font-weight: 800;
    font-size: 65px;
    text-transform: uppercase;
    letter-spacing: 5px;
    color: white;
    padding: 45px 0;
}

@media (max-width: 1000px) {
    .EventPageBannerTitle {
        font-size: 24px;
    }
    .EventPageBannerContainer {
        margin-left: -3%;
        margin-right: -3%;
    }
}