.InfiniteScrollContainer {
  display: flex;
  margin: 0 -20% 0 -20%;
  position: relative;
}

.InfiniteScrollContainer:hover > .ScrollButton {
  display: flex;
}

.ScrollItemContainer {
  margin-right: 20px;
  box-shadow: 3px 2px 5px 0px lightgrey;
  display: inline-block;
  position: relative;
}

.ScrollButton {
  position: absolute;
  background-color: rgba(230, 230, 230,0.5);
  height: 330px;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 40px;
  z-index: 1;
}

.ScrollRightButton {
  right: 12.5%;
}

.ScrollLeftButton {
  left: 12.5%;
}

.ScrollIcon {
  height: 23px;
  color: white;
}

.ScrollRight {
  margin-right: 7px;
  transform: rotate(210deg);
}

.ScrollLeft {
  margin-left: 7px;
  transform: rotate(150deg);
}

.ScrollableComponent {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.ScrollableComponent::-webkit-scrollbar {
      display: none;
}

.DeleteButton {
  cursor: pointer;
  position: absolute;
  height: 25px;
  width: 25px;
  color: var(--main-redness);
  border: 1px solid var(--main-redness);
  background-color: white;
  border-radius: 50px;
  padding: 4px;
  display: flex;
  justify-content: center;
  margin: 5px;
}

@media (max-width: 1000px) {
  .InfiniteScrollContainer {
    margin: 0;
  }
  .ScrollButton {
    display: flex;
    background-color: unset;
  }
  .ScrollRightButton {
    right: 0;
  }
  .ScrollLeftButton {
    left: 0;
  }
}