.AboutUsContentContainer {
    height: 100%;
    text-align: justify;
    font-family: Montserrat, sans-serif;
}

.AboutUsTitle {
    font-family: Poppins, sans-serif;
    color: rgb(0, 0, 0);
    font-size: 3rem;
    line-height: 4rem;
    margin: 0px 0px 1.875rem;
    font-weight: 700;
}