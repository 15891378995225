.MemberThumbnailContainer {
    height: 500px;
    width: 300px;
    margin: 50px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
}

.MemberImageContainer {
    min-height: 70%;
    width: 300px;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.MemberDetails {
    overflow-y: scroll;
    padding: 0 10px;
    font-size: 11pt;
    line-height: 12pt;
    font-weight: 500;
    max-height: 50%;
    margin-bottom: 10px;
}

.MemberName{
    color: white;
    background-color: var(--main-redness);
    margin-bottom: 2px;
    padding: 10px;
    font-size: 13pt;
    line-height: 18pt;
    font-weight: 600;
}

::-webkit-scrollbar {
    width: 4px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.NewMemberThumbnailContainer {
    height: 500px;
    width: 300px;
    margin: 50px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
}

@media (max-width: 1000px) {
    .MemberThumbnailContainer {
        margin: 10px;
    }
}