.InnerPageContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    text-indent: 25px;
    text-align: left;
    text-indent: 0;
}

.InnerPageTitle {
    font-size: 30px;
    color: rgba(120, 114, 114, 0.8);
    font-weight: bolder;
    padding-right: 5%;
    width: 30%;
}

.InnerPageRightSection {
    display: flex;
    flex-direction: column;
    text-align: justify;
    width: 100%;
    max-height: 500px;
    overflow-y: overlay;
    padding: 0 20px;
    margin-bottom: 10px;
}

.InnerPageSubTitle {
    font-weight: bold;
    text-indent: 0;
    font-size: large;
}

@media (max-width: 1000px) {
    .InnerPageTitle {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 10px;
    }
}