.NewEventThumbnailContainer {
    height: 370px;
    width: 500px;
    margin: 50px 0 50px 0;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 0px, rgb(0 0 0 / 10%) 0px 0px 1px 0px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    justify-content: center;
    padding-bottom: 10px;
    cursor: pointer;
    border: 1px solid white;
}

.NewEventThumbnailContainer:hover {
    border: 1px solid;
}