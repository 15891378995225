.MemberListContainer {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: flex-start;
}

.MembersSectionContainer {
    display: flex;
    flex-direction: column;
}

@media (max-width: 1000px) {
    .MemberListContainer {
        flex-direction: column;
        align-items: center;
    }
}