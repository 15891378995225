.SponsorsContainer {
    display: flex;
    flex-direction: column;
    align-items: initial;
    width: -moz-fit-content;
    width: 100%;
    padding: 0 3%;
}

.SponsorsListContainer {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
}

.SponsorImageContainer {
    width: 100%;
    max-height: 70px;
    cursor: pointer;
}

.SponsorContainer {
    display: flex;
    width: 100px;
    margin-right: 20px;
}

.AddSponsor {
    cursor: pointer;
}

.SponsorContainerParent {
    position: relative;
    margin-bottom: 20px;
}

.SponsorDeleteButton {
  cursor: pointer;
  position: absolute;
  height: 20px;
  width: 20px;
  color: red;
}

.SponsorYearContainer {
    margin-bottom: 20px;
}

@media (max-width: 1000px) {
    .SponsorsContainer {
        align-items: center;
    }
    .SponsorsListContainer {
        justify-content: center;
    }
}