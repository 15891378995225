.NewsPageContainer {
    display: flex;
    flex-direction: row;
    justify-content: initial;
}

.NewsPageContainer > .NewsColumn:not(:last-child) {
    margin-right: 2%;
    padding-right: 3%;
    padding-top: 20px;
}

.NewsColumn {
    display: flex;
    flex-direction: column;
    max-height: 700px;
    overflow-y: scroll;
    overflow-x: hidden;

    -ms-overflow-style: none;  
    scrollbar-width: none;  
}

.RightColumn {
    width: 80%;
}

.NewsImageContainer {
    height: 70px;
    width: 100px;
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.LargeNewsImageContainer {
    height: 320px;
    width: 600px;
}

.NewsNameContainer {
    display: -webkit-box;
    max-width: 200px;
    font-size: 10px;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.LargeNewsNameContainer {
    max-width: 1000px;
    font-size: 30px;
    color: #2578b3;
    margin-bottom: 20px;
}

.LargeNewsDescriptionContainer {
    margin-top: 20px;
    text-align: justify;
}

.NewsName {
    text-align: initial;
}

@media (max-width: 1000px) {
    .NewsColumn {
        flex-direction: row;
        overflow-y: hidden;
        overflow-x: overlay;
    }
    .NewsPageContainer {
        flex-direction: column;
    }
    ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }
    .RightColumn {
        width: auto;
        flex-direction: column;
        overflow-x: hidden;
        
        overflow-y: auto !important;
        height: 100%;
    }
    .NewsPageContainer > div:first-child {
        margin-bottom: 30px;
    }
    .LargeNewsDescriptionContainer {
        padding: 0 10px;
    }
}