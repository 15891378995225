.HomeCarousel {
    width: 100%;
}

.HomeCarouselItem{
    display: flex;
}

.carousel-control-prev, .carousel-control-next {
    z-index: 0;
}

.InfiniteScroll {
    display: flex;
    overflow-x: scroll;
    -ms-overflow-style: none;  
    scrollbar-width: none;  
    margin: 0 -20%;
    margin-bottom: 30px;
}

.InfiniteScroll::-webkit-scrollbar {
  display: none;
}

.InfiniteScroll > div {
    margin-right: 15px;
}

.HomePageImageContainer {
    height: 330px;
    min-width: 490px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.HomePageContentContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.HomePageAboutUsContainer {
    max-width: 70%;
    text-align: justify;
    margin: 5% 0;
}

.player-wrapper {
    width: auto; 
    height: auto; 
}
.react-player {
    padding-top: 56.25%; 
    position: relative; 
}

.react-player > div {
    position: absolute;
}

.HomePageEditButtonContainer {
    cursor: pointer;
    position: absolute;
    height: 25px;
    width: 25px;
    color: var(--main-redness);
    border: 1px solid var(--main-redness);
    background-color: white;
    border-radius: 50px;
    padding: 4px;
    display: flex;
    justify-content: center;
    margin: 5px;
    align-items: center;
}