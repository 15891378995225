.PageSubTitle {
    display: flex;
    color: rgba(22, 22, 23, .8);
    font-size: 30px;
}

.WhiteText {
    color: rgba(255, 255, 255, .8);
}

@media (max-width: 1000px) {
    .PageSubTitle {
        justify-content: center;
    }
}