.MenuContainer {
    display: flex;
    justify-content: space-between;
    margin: 55px 0;
    align-items: center;
    padding: 0 3%;
}

.MenuItem { 
    color: rgba(255, 255, 255, .8);
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
    height: 50px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
}
  
.MenuVerticalBar {
    border-right: 1px solid rgba(22, 22, 23, .8);
    height: 80px;
    margin: 0 10px;
}

.LanguageSwitch {
    color: rgba(22, 22, 23, .8);
    margin: 0 10px;
    cursor: pointer;
    position: relative;
    height: 50px;
    display: flex;
    align-items: center;
}

.MenuLanguageSymbol {
    margin-right: 10px;
    height: 20px;
}

.MenuItemLink {
    text-decoration: unset;
    color: rgba(22, 22, 23, .8);
}

.MenuLogoImage {
    height: 70px;
    width: 306px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.MenuItems {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.MenuLogoContainer {
    display: flex;
    flex-direction: row;
}

@media (max-width: 1000px) {
    .MenuContainer {
        flex-direction: column;
        margin-bottom: 0;
        margin-top: 25px;
        position: relative;
        border-bottom: 2px solid var(--main-redness);
    }
    .MenuItem {
        height: -moz-fit-content;
        height: fit-content;
        padding: 10px 0;
        width: 100%;
        align-items: center;
        border-bottom: 1px solid #d1d1d1;
    }
    .MenuItem > .MenuItem {
        border-bottom: none;
        font-size: small;
        margin-left: 100%;
        text-align: initial;
    }
    .LanguageSwitch > .MenuItem {
        border-bottom: none;
    }
    .MenuItems {
        flex-direction: column;
        position: absolute;
        z-index: 2;
        background-color: white;
        top: 82px;
        width: 100%;
        border: 1px solid #d1d1d1;
        border-top-width: 0;
    }
    .MenuVerticalBar {
        display: none;
    }
    .MenuButton {
        height: 50px;
        width: 50px;
        padding-top: 14px;
        margin-left: 20px;
    }
    .MenuIcon {
        width: 25px;
    }
    .MenuLogoContainer {
        margin-bottom: 10px;
    }
    .MenuItemLabelContainer {
        width: 100%;
        justify-content: space-between;
        position: relative;
    }
    .MenuArrowDown {    
        position: absolute;
        right: 9.5%;
        bottom: 50%;
    }
}