.App {
  text-align: center;
  height: 100%;
  position: relative;
}

.AppContentSection {
  padding: 0 3%;
  overflow: hidden;
  min-height: 60%;
}

.PageFont {
  font-family: sans-serif;
  color :rgba(22, 22, 23, .8);
}

.GreyPanelColor {
  background-color: #f2f2f2
}

:root {
  --main-redness: rgba(121,9,9,1);
}