.AccordionContainer {
    margin: 20px;
}

.AccordionArrowContainer {
    display: flex;
    align-items: center;
    font-size: 14px;
    text-align: initial;
    cursor: pointer;
    justify-content: space-between;
}

.AccordionChildrenContainer {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
}
